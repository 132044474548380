import "styles/pages/page-industries.scss";
import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import Banner from "../components/Banner";
import SubpageHeader from "../components/SubpageHeader";
import SectionHeading from "../components/SectionHeading";
import WhiteTile from "components/WhiteTile";
import classNames from "classnames";

const IndustriesPage = ({ pageContext }) => {
  const indst = pageContext.pageContent;

  return (
    <Layout>
      <Seo
        title={
          pageContext.seo.title ? pageContext.seo.title : pageContext.title
        }
        description={pageContext.seo?.description}
      />
      <SubpageHeader
        title={pageContext.title}
        image={require("assets/images/industries-bg.jpg").default}
      />
      <Banner title={indst.divider1Text} />
      <section className="industries-tiles">
        <div className="container">
          <SectionHeading variant="verySmall" subtitle={indst.tileTitle} />

          <div className="row">
            {indst.tiles.map((item, index) => {
              return (
                <div className="col-lg-4" key={index}>
                  <WhiteTile hiddenText={item.hiddenText}>
                    {item.tileContent}
                  </WhiteTile>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default IndustriesPage;
